import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';

const Visitors = () => {
    const [inputVisitors, setInputVisitors] = useState({
        Name: "",
        Phone: "",
        Email: "",
        CompanyName: "",
        Availability: "",
        BusinessArea: ""
    });
    const [data1, setData1] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        fetch('https://sheetdb.io/api/v1/jayxc48ozmfil')
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setData1(data);
            });
    }
    // css

    const logostyle = {
        height: "75%",
        width: "50%",
    }
    const inputBox = {

        outline: "0",
        borderWidth: "0 0 2px",
        borderColor: "green",
    }

    // css end

    const inputVisitorsHandler = (e) => {

        const { name, value } = e.target;
        setInputVisitors((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const save = (evt) => {
        evt.preventDefault();
        setLoading(true);
        var emailValidation = data1.filter((x) => x.Email === inputVisitors.Email);
        debugger
        if (emailValidation.length > 0) {
            setLoading(false);
            alert("Email Already Registered")
        
            return;
        }
        var data = {
            ...inputVisitors,
        }
        console.log(data);
        debugger

        fetch('https://sheetdb.io/api/v1/jayxc48ozmfil', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        })
            .then((response) => response.json())
            .then((data) => {

                if (data.created === 1) {
                    setLoading(false);
                    alert("Data Submitted successfully");
                    window.location.href = "/";

                }
                else {
                    alert("Error Occured Try Again Later")
                }
            });

    }

    return (
        <div className="container-fluid p-3">

            <div className="row justify-content-center p-3">
                <div className="col-sm-10 justify-content-center p-1 d-flex" style={{ border: "5px solid black" }}>

                    <div className="col-sm-12" style={{ border: "3px solid black" }}>
                        <div className="col-sm-12 d-flex flex-wrap mb-4">

                            <div className="col-md-4 d-flex justify-content-center align-items-center">
                                <img src="https://seeklogo.com/images/A/apeda-logo-36F047E5C1-seeklogo.com.png" style={logostyle} alt="" />
                            </div>
                            <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                                <h4>APEDA 2024</h4>
                                <h4>5<sup>th</sup> to 7<sup>th</sup> May 2024</h4>
                                <h4>Delhi, India</h4>
                            </div>
                            <div className="col-md-4 d-flex align-items-center justify-content-md-end justify-content-center">
                                <Link to="/" className="btn btn-info">Back</Link>
                            </div>
                        </div>
                        <div className="col-sm-12  mt-4 text-center">
                            <h4>Feedback Form</h4>
                        </div>
                        <div className="col-sm-12 mt-3 border border-dark   ">
                            <form onSubmit={(e) => save(e)} className='form p-2'>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 required-label'><b>Name</b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='Name' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} required />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 required-label'><b>Company </b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='CompanyName' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 required'><b>Email</b></div>
                                        <div className='col-sm-10'>
                                            <input type="email" name='Email' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 required-label'><b>Contact Number</b></div>
                                        <div className='col-sm-10'>
                                            <input type="number" name='Phone' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 required-label'><b>Area Of Business</b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='BusinessArea' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-6  required-label'><b>Did you get to see the product you are looking for?</b></div>
                                        <div className='col-sm-2 d-flex'>
                                            <div>Yes</div>
                                            <input type="radio" name="Availability" value={true} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-sm-2 d-flex'>
                                            <div>No</div>
                                            <input type="radio" name="Availability" value={false} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>
                                {loading === false ? <h6 className="font-weight-bold text-primary">{ }</h6> :
                                    <div className=" text-primary">
                                        <strong>Saving...</strong>
                                        <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                }
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-10 mt-3'>
                                            <button type='submit' className="btn btn-success">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Visitors