import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Visitors from "./Visitors";
import Exhibitors from "./Exhibitors";
import Home from "./Home";
 
function Router() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={<Home />}
                    />
                    <Route
                        exact
                        path="/Visitors"
                        element={<Visitors />}
                    />
                     <Route
                        exact
                        path="/Exhibitors"
                        element={<Exhibitors />}
                    />
                </Routes>
            </BrowserRouter>
        </>
    );
}
 
export default Router;