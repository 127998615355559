import { Link } from 'react-router-dom';
import './App.css';

const imgStyle = {
  maxWidth: "80%",
  height: "60%"
}

function Home() {
  return (
    <div className="container-fluid">
      <div className="row align-items-center justify-content-center  flex-column p-3">
        <div className="col-sm-4  d-flex justify-content-center" >
          <img src="https://seeklogo.com/images/A/apeda-logo-36F047E5C1-seeklogo.com.png" style={imgStyle} alt="" />
        </div>
        
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-sm-4 mt-2">
          <Link to="/Visitors" type="button" class="btn btn-primary btn-lg btn-block">Visitor</Link>
        </div>
        <div className="col-sm-4 mt-2">
          <Link to="/Exhibitors" className="btn btn-primary btn-lg btn-block">Exhibitor</Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
